<template lang="pug">
    #aplicativos
        ul
            li(v-for="aplicativo in contents.content")
                a(:contents="aplicativo" :href="aplicativo.link")
                    SvgIcon(:data="icons[aplicativo.app]", original)
</template>

<script>
import { props } from '@/mixins/component'

export default {
    name: 'component-aplicativos',
    props,
    data() {
        return {
            icons: {
                'android': require("@svgs/google-play-badge.svg"),
                'ios': require("@svgs/app-store.svg"),
            },
        }
    },
}
</script>

<style lang="stylus" scoped src="./Aplicativos.styl"></style>
